import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../classes/api-response';

@Injectable({
  providedIn: 'root'
})
export class MiejscaService {

  constructor(private _http: HttpClient) { }

  find(): Observable<APIResponse> {
    let s = environment.api_url + "pass/add";
    console.log("MIEJSCALIST: " + s);
    return this._http.get<APIResponse>(s);
  }

}
